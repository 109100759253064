import * as Sentry from '@sentry/vue'

export const initialiseSentry = (app, environment) => {
  Sentry.init({
    app,
    environment: environment,
    dsn: 'https://cbb334603fdba1c9f4317102c72a6a96@o4507463514849280.ingest.de.sentry.io/4507463558627408',
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_BASE_URL],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

/**
 * For sending exceptions to sentry, can also take extra details via the context param
 * @param {*} error
 * @param {Object} context
 * @returns {void}
 */
export const sentryException = (error, context = {}) => {
  /**
   * We can pass in extra details to the context param if required
   */
  Sentry.withScope((scope) => {
    scope.setExtras(context.extra)
    Sentry.captureException(error)
  })
}


export const SENSOR_STATUSES = {
  NORMAL: 'normal',
  LOW_BATTERY: 'low_battery',
  ERROR: 'error',
  DORMANT: 'dormant',
}

export const VALID_IMAGE_TYPES = {
  JPEG: '.jpeg',
  PNG: '.png',
  JPG: '.jpg',
}

export const INVALID_CHART_SELECTION_LABELS = {
  TIMESTAMP: 'timestamp',
  TIMESTAMP_UNIX: 'timestamp_unix',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
}

export const VALID_GPS_KEYS = {
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
}

export const BASIC_LIST_SORTING_OPTIONS = {
  ALPHABETICALLY: 'Alphabetically',
  NUMBER_OF_LOCATIONS: 'Number of locations',
}

export const FETCH_ERRORS = {
  NETWORK_ERROR: 'NetworkError when attempting to fetch resource.',
  ABORT_ERROR: 'The operation was aborted. ',
}

export const SENSOR_CHART_ACTION_OPTIONS = {
  EXPORT: 'Export CSV'
}
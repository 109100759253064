import { defineStore } from 'pinia'
import { ref } from 'vue'
import { ofetch } from 'ofetch'
import { nanoid } from 'nanoid'
import qs from 'qs'
import { base64URLEncode, sha256 } from '@/utils'
import { usePortalAPI } from '@/composables/usePortalAPI'
import { sentryException } from '@/plugins/sentry.js'

const client_id = import.meta.env.VITE_APP_ONELOGIN_CLIENTID
const oneLoginDomain = import.meta.env.VITE_APP_ONELOGIN_DOMAIN
const appBaseURL = import.meta.env.VITE_APP_BASE_URL

export const useAuthStore = defineStore(
  'authStore',
  () => {
    const user = ref(null)
    const token = ref(null)
    const refreshToken = ref('')
    const refreshTimeout = ref(null)
    const authObj = ref({})
    const accessTokenExpiry = ref(null)
    const isAuthenticated = ref(false)
    const loading = ref(true)
    const hasRAG = ref(false)
    const missingUser = ref(false)

    const fetchUser = async () => {
      loading.value = true
      try {
        const response = await usePortalAPI('users/my-info/', {
          method: 'GET',
        })
        if (response) {
          user.value = {
            ...response.user,
            profile_image: response.get_image,
            projectId: response.project,
          }
        }
        loading.value = false
      } catch (error) {
        /**
         * Need to do some custom routing for a missing user.
         */
        if (error.response?.status === 404) {
          missingUser.value = true
        }
        throw error
      }
    }

    const updateUserProfileImage = async (formData) => {
      try {
        const response = await usePortalAPI('controlpanel/users/update-self/', {
          method: 'PATCH',
          body: formData,
        })
        if (response) {
          /**
           * Refetch user if request is succesful
           */
          await fetchUser()
        }
      } catch (error) {
        console.log(error)
      }
    }

    const logoutUserFromAPI = async () => {
      try {
        await usePortalAPI('users/api-logout/', {
          method: 'POST',
        })
      } catch (error) {
        sentryException(error)
      }
    }

    /**
     * OpenId Connect Auth Actions
     */

    const $login = () => {
      const verifier = nanoid(32)
      const state = nanoid()
      const nonce = nanoid()
      const codeChallenge = base64URLEncode(sha256(verifier))

      localStorage.setItem('verifier', verifier)

      const params = {
        client_id: client_id,
        redirect_uri: `${appBaseURL}/auth/callback`,
        response_type: 'code',
        scope: 'openid profile',
        state: state,
        nonce: nonce,
        code_challenge_method: 'S256',
        code_challenge: codeChallenge,
      }

      const redirectURL = `https://${oneLoginDomain}.onelogin.com/oidc/2/auth?${qs.stringify(
        params,
      )}`
      window.location.replace(redirectURL)
    }

    const $loginSecondStage = async (code) => {
      const verifier = localStorage.getItem('verifier')
      try {
        const response = await ofetch(
          `https://${oneLoginDomain}.onelogin.com/oidc/2/token`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              grant_type: 'authorization_code',
              code: code,
              client_id: client_id,
              code_verifier: verifier,
              redirect_uri: `${appBaseURL}/auth/callback`,
            }),
          },
        )
        if (response) {
          // Convert to milliseconds
          const expires = response.expires_in * 1000

          if (expires <= 2100000000) {
            refreshTimeout.value = setTimeout(() => $refresh(), expires - 10000)
          }
          accessTokenExpiry.value = Date.now() + expires
          setAuthDetails(response)
          isAuthenticated.value = true
          localStorage.removeItem('verifier')
        }
      } catch (error) {
        sentryException(error)
        throw new Error()
      }
    }

    const $refresh = async () => {
      // Skip refresh if the token is still fresh
      if (
        accessTokenExpiry.value &&
        Date.now() < accessTokenExpiry.value - 10000
      ) {
        clearTimeout(refreshTimeout)
        refreshTimeout.value = setTimeout(
          () => $refresh(),
          accessTokenExpiry.value - 10000 - Date.now(),
        )
        return
      }
      try {
        const response = await ofetch(
          `https://${oneLoginDomain}.onelogin.com/oidc/2/token`,
          {
            method: 'POST',
            body: new URLSearchParams({
              grant_type: 'refresh_token',
              refresh_token: refreshToken.value,
              client_id: client_id,
            }),
          },
        )
        if (response) {
          clearTimeout(refreshTimeout)
          const expires = response.expires_in * 1000

          if (expires <= 2100000000) {
            refreshTimeout.value = setTimeout(() => $refresh(), expires - 10000)
          }
          accessTokenExpiry.value = Date.now() + expires
          setAuthDetails(response)
        }
      } catch (error) {
        if (authObj.value.id_token) {
          await $logout()
        }
        window.location.replace(`${appBaseURL}/auth/logout`)
      }
    }

    const $logout = async () => {
      const logoutRedirectURI = new URL(
        `https://${oneLoginDomain}.onelogin.com/oidc/2/logout`,
      )
      logoutRedirectURI.searchParams.append(
        'id_token_hint',
        authObj.value.id_token,
      )
      logoutRedirectURI.searchParams.append(
        'post_logout_redirect_uri',
        `${appBaseURL}/auth/logout`,
      )
      await logoutUserFromAPI()
      window.location.replace(logoutRedirectURI)
    }

    /**
     * @param {Object} details
     * @return {void}
     * */
    const setAuthDetails = (details) => {
      authObj.value = details
      token.value = details.access_token
      refreshToken.value = details.refresh_token
    }

    const clearAuthState = () => {
      user.value = null
      token.value = null
      refreshToken.value = ''
      authObj.value = {}
      accessTokenExpiry.value = null
      isAuthenticated.value = false
      missingUser.value = false
    }

    return {
      fetchUser,
      setAuthDetails,
      clearAuthState,
      updateUserProfileImage,
      logoutUserFromAPI,
      isAuthenticated,
      token,
      refreshToken,
      authObj,
      accessTokenExpiry,
      user,
      loading,
      missingUser,
      $login,
      $loginSecondStage,
      $logout,
      $refresh,
    }
  },
  {
    persist: {
      storage: localStorage,
    },
  },
)

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { initialiseSentry } from '@/plugins/sentry'
import { useAuthStore } from './store/Auth'

library.add(fas)
const environment = import.meta.env.VITE_APP_ENVIRONMENT
const app = createApp(App)

app.use(createPinia().use(piniaPluginPersistedstate))

const authStore = useAuthStore()
if (authStore.isAuthenticated) {
  authStore.$refresh()
}

/**
 * Make sure to initialise sentry after pinia has been created
 * as the auth store is required once authentication has been complete.
 */
if (environment !== 'development') {
  initialiseSentry(app, environment)
}

app.use(router).component('fa-icon', FontAwesomeIcon).mount('#app')
